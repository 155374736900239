$content-width: 800px;
$default-font-size: 1.1em;

$main: #1d1d7f;
$font-style: 'Open Sans', sans-serif;
$font-color: #262626;
$link-color: #425469;
$link-hover-color: $main;
$heading-font: 'Muli', sans-serif;
$heading-font-color: #425469;
$light: #e7edf4;
$header: #e7e7e7;

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@font-face {
  font-family: "RollingNoOne";
  src:
    url('../fonts/RollingNoOne-ExtraBold.woff') format('woff'),
    url('../fonts/RollingNoOne-ExtraBold.woff2') format('woff2');
}

@font-face {
  font-family: "UbuntuMono-Regular";
  src:
    url("../fonts/UbuntuMono-R.woff") format('woff'),
    url("../fonts/UbuntuMono-R.woff2") format('woff2');
}

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin larger-than-phone {
  @media (min-width: 600px) {
    @content;
  }
}
