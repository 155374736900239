@import 'base';

body {
  margin: 0;
  color: $font-color;
  font-family: $font-style;
  font-size: 1.1em;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3 {
  font-weight: 600;
  color: $heading-font-color;
  font-family: $heading-font;
  line-height: 1.5;
}

h1 {
  color: $main;
  // font-size: 2.5em;

  a,
  a:visited {
    text-decoration: none;
    color: $main;
  }
}

h2 {
  font-size: 2em;
}

h1:has(+ p),
h2:has(+ p),
h3:has(+ p),
h4:has(+ p),
h5:has(+ p),
h6:has(+ p) {
  margin-bottom: 0px;
}

h1+p,
h2+p,
h3+p,
h4+p,
h5+p,
h6+p {
  margin-top: 2px;
  // margin-top: 0px;
}

a,
a:visited {
  color: $link-color;
  text-decoration: underline;
  font-weight: bold;
}

a:hover {
  color: $link-hover-color;
  text-decoration: underline;
}

header {
  font-size: clamp($default-font-size, 0rem + 6.133vw, calc($default-font-size * 2.85));

  background: $header;
  padding: 0px 15px;
  text-align: center;
  height: clamp(9vh, 5rem + 8.133vw, 11vw);
  display: flex;
  justify-content: center;
  align-items: center;

  * {
    font-family: RollingNoOne;
  }

  a:active,
  a:hover {
    text-decoration: none;
  }
}

main {
  padding: 0 15px;
  max-width: $content-width;
  margin: 0 auto;
}

time {
  color: #898989;
}

.container {
  max-width: $content-width;
  margin: 0 auto;
}


nav {
  display: flex;
  flex-direction: column;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;

    li {
      a {
        text-decoration: none;
        display: block;
        padding: 15px;
        font-family: 'Muli', sans-serif;
        line-height: 1.5;
        margin-bottom: -1px;
        box-shadow: 0;

        &:hover,
        &:active {
          background: $header;
          text-decoration: none;
        }
      }
    }
  }
}

footer {
  text-align: center;
  padding: 40px;

  display: flex;
  justify-content: flex-end;

  @include for-phone-only {
    font-size: calc(0.8 * $default-font-size);
  }

  * {
    font-size: xx-small;
  }
}
