@import 'base';

.distraction-free {
  margin: 0;
  color: $font-color;
  font-family: $font-style;
  font-size: $default-font-size;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  height: 100vh;

  display: flex;
  flex-direction: column;
  gap: 1vh;

  header {
    display: none;
  }

  .header {
    background-color: lighten($header, 8%);
  }

  main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 85vh;

    @include for-phone-only {
      height: 70vh;
      padding: 2vh 1vw;
    }
  }

  article {
    @include for-phone-only {
      max-width: 98vw;
    }
  }
}

.main-content__schulte-container {
  font-family: "UbuntuMono-Regular";
  display: grid;
  align-items: center;
  justify-content: center;

  grid-template-columns: repeat(5, 60px);
  grid-template-rows: repeat(5, 60px);

  & * {
    place-self: stretch stretch;
    background-color: white;
    font-size: 37px;
    text-align: center;
    border: 1px solid black;
  }
}

.working-memory__score-table {
  display: grid;
  grid-template-rows: 8fr 1fr 1fr;
  grid-auto-flow: column;

  * {
    align-self: end;
  }

  .bar {
    border: 1px solid #008000;
    background-color: #40b040;
  }
}

.main-content__working-memory {
  display: flex;
  flex-direction: column;

  .mask {
    visibility: hidden;
  }

  .working-memory__assignment,
  .working-memory__solution_outline,
  .working-memory__solution {
    font-family: "UbuntuMono-Regular";
    display: flex;
    flex-direction: row;
    gap: 1em;
    width: 46em;

    @include for-phone-only {
      width: unset;
      max-width: 95vw;
      gap: calc(95vw / 11 * 0.45);
    }

    * {
      height: 3em;
      width: calc((46em - 11em) / 11);
      text-align: center;
      padding-top: 0.6em;
      padding-bottom: 1em;

      @include for-phone-only {
        width: 4.5vw;
      }
    }
  }

  .working-memory__assignment *,
  .working-memory__solution_outline * {
    border: 1px solid black;
  }

  .working-memory__solution-wrapper {
    display: grid;
    gap: 2em
  }

  .working-memory__solution_outline,
  .working-memory__solution {
    grid-column-start: 1;
    grid-row-start: 1;
  }

  .working-memory__solution_outline,
  .working-memory__solution,
  .working-memory__submit,
  .working-memory__spinner {
    position: relative;
    top: -3em;
  }

  .working-memory__submit {
    display: none
  }

  .working-memory__submit,
  .working-memory__start {
    grid-row-start: 2;
    max-width: 8em;
    height: 3em;
  }

  .working-memory__solution {
    background-color: unset;
    outline: none;
    border: none;
    font-size: inherit;
    letter-spacing: 3.5em;
    width: 50em;
    letter-spacing: calc(-0.5em + (46em / 11));
    padding-left: 1.2em;
    caret-color: transparent;
    overflow: unset;

    @include for-phone-only {
      columns: 11;
      column-gap: 4.5em;
      padding-left: 1vw;
      max-width: 98vw;
      letter-spacing: calc(-0.5em + 93vw / 11);
    }

    &:focus {
      outline: none;
    }
  }
}

.clock {
  color: #888;
}

nav {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;

    li {
      a {
        text-decoration: none;
        display: block;
        padding: 15px;
        font-family: 'Muli', sans-serif;
        line-height: 20px;
        margin-bottom: -1px;
        box-shadow: 0;
        font-weight: bold;
        color: $link-color;
        font-weight: bold;

        &:visited {
          color: $link-color;
          font-weight: bold;
        }

        &:hover,
        &:active {
          background: $header;
          text-decoration: none;
          color: $main;
        }
      }
    }
  }
}
